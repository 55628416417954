<template>
  <full-view stretch-toolbar>
    <template v-slot:toolbar>
      <v-text-field
        v-model="search"
        hide-details
        solo
        flat
        dense
        clearable
        clear-icon="mdi-close"
        :placeholder="$t('general.search') + '...'"
        style="width: 300px;"
        @click:append="() => (showSearch = !showSearch)"
      >
      </v-text-field>
    </template>

    <v-card tile flat class="pa-5">
      <v-row>
        <v-col
          xl="2"
          lg="3"
          md="4"
          sm="6"
          cols="6"
          v-for="(item, i) in filterStores"
          :key="i"
          class="pr-2"
        >
          <store-card :store="item" />
        </v-col>
      </v-row>
    </v-card>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";
import StoreCard from "@/views/stores/StoreCard";

export default {
  name: "Search",
  components: { FullView, StoreCard },
  data() {
    return {
      search: null,
    };
  },
  mounted() {
    this.loadStoresByParentId(this.getRegionId);
  },
  computed: {
    ...mapGetters("stores", ["getStoresByParentId"]),

    getRegionId() {
      const { regionId } = this.$getUserInfo();
      return regionId;
    },
    filterStores() {
      if (!this.search || this.search.length < 3) {
        return null;
      }

      const words = this.search.trim().split(" ");
      const stores = this.getStoresByParentId(this.getRegionId);
      return this.notEmptyArray(stores)
        ? stores.filter((f) =>
            words.every(
              (w) =>
                f.name
                  .toString()
                  .toLocaleLowerCase()
                  .indexOf(w.toLocaleLowerCase()) !== -1 ||
                (f.description &&
                  f.description
                    .toLocaleLowerCase()
                    .indexOf(w.toLocaleLowerCase()) !== -1)
            )
          )
        : null;
    },
  },
  methods: {
    ...mapActions("stores", ["loadStoresByParentId"]),
  },
};
</script>
